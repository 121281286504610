<template>
<div>
  <v-row justify="center" v-if="!grid">
    <v-col ms="8" lg="9">
    <v-carousel height="auto" hide-delimiters>
      <v-carousel-item v-for="spread, i in bookSpread" :key="i">
              <v-row>
                <v-col cols="6">
                  <v-card class="zoom-cursor">
                    <v-img
                      @click="show(spread[0])"
                      :src="getUrl(spread[0])"
                      ></v-img>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card class="zoom-cursor">
                    <v-img
                      @click="show(spread[1])"
                      :src="getUrl(spread[1])"
                      ></v-img>
                  </v-card>
                </v-col>
              </v-row>
      </v-carousel-item>
    </v-carousel>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col v-for="tile in tiles" :key="tile" cols="12" md="3" lg="2">
        <v-hover v-slot="{ hover }">
        <v-lazy min-height="100" :options="{ threshold: .5 }">
          <v-card
          v-bind:class="{ 'zoom-cursor' : !isMobile }"
          :elevation="hover ? 5 : 1">
            <v-img
              @click="show(tile)"
              :lazy-src="getMinUrl(2)"
              :src= "isMobile ? getUrl(tile) : getHalfUrl(tile)"
              >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <div v-if="hover && !isMobile" style="height: 100%;" class="d-flex justify-center align-center">
                  <v-icon class="huge" color="dark">
                    mdi-magnify-plus-outline
                  </v-icon>
              </div>
            </v-img>
          </v-card>
        </v-lazy>
        </v-hover>
    </v-col>
  </v-row>
  </div>
</template>
<script>
  import 'viewerjs/dist/viewer.css'
  export default {
    props: {
      grid: {
        type: Boolean,
        default: true
      },
      isMobile: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        size: 64,
        images: [],
        tiles: [],
        bookSpread: [],
        tileIgnore: [0, 2, 63],
      };
    },
    created() {
      for(var i = 0; i < this.size; i++) {
        this.images.push(`pages/${i}.jpg`);
        if( this.tileIgnore.indexOf(i) == -1 ) {
          this.tiles.push( i );
        }
          // кол-во страниц n, гарантированно четное, [0, .... n-1] 
        if( i % 2 == 0) {
          this.bookSpread.push([i, i+1]);
        }
      }
    },
    methods: {
      getUrl(index) {
        return this.images[index];
      },
      getHalfUrl(index) {
        return `half/${index + 1}.jpeg`
      },
      getMinUrl(index) {
        return `low/${index + 1}.jpeg`
      },
      inited (viewer) {
        this.$viewer = viewer
      },
      show(index) {
        if(this.isMobile) {
          return;
        }
        this.$viewerApi({
          images: [this.getUrl(index)],
            options: {
            ready (e) {
              console.log(e.type)
            },
            navbar: false,
            toolbar: false,
            initialViewIndex: 0
          },
        })
      },
    },
  }
</script>
<style scoped>
.zoom-cursor {
  cursor: zoom-in;
}
.huge {
  font-size: 84px;
}
</style>