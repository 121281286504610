<template>
  <v-app id="app">
    <v-app-bar
      app
      absolute
      color="grey darken-4"
      dark
      prominent
      height="20"
    >
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-btn
            href="biography.pdf"
            download="биография Грибов А.В.pdf"
            outlined
            color="blue-grey lighten-5"
            class="ma-2 white--text"
          >
            Скачать
            <v-icon
              right
              dark
            >
              mdi-cloud-download
            </v-icon>
          </v-btn>
          <v-tab @click="grid=true" v-if="!isMobile">Плитка</v-tab>
          <v-tab @click="grid=false" v-if="!isMobile">Страницы</v-tab>
          <v-spacer></v-spacer>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main >
      <v-container fluid>
      <main-page :grid="grid" :isMobile="isMobile"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainPage from './components/MainPage.vue'

export default {
  name: 'App',
  components: {
    MainPage
  },
    data () {
      return {
        grid: true,
        drawer: true,
        isMobile: false,
        mini: true,
      }
    },
    beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },

    mounted () {
      this.onResize()

      window.addEventListener('resize', this.onResize, { passive: true })
    },

    methods: {
      onResize () {
        this.isMobile = window.innerWidth < 960
      },
    },
}
</script>

<style>
</style>
